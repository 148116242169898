import React, { useEffect } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import LatestNews from '../components/LatestNews'
import { addGoogleAnalyticsPageData } from '../utils/googleAnalyticsEvents'

const LatestNewsPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const seoTitle =
    'أخبار عاجلة - تابع آخر الاخبار العربية والعالمية | الشرق للأخبار'
  const seoDescription =
    'تابع آخر الاخبار العاجلة العربية والعالمية أول بأول من خلال موقع الشرق للأخبار، حيث نطلعكم على اخر المستجدات العربية والعالمية يوميا. قم بالدخول وتعرف على كل ما هو جديد.'

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <Layout location={location} title={siteTitle} lang={'ar'}>
      <h1 className="customH1">{'آخر الأخبار'}</h1>
      <SEO
        title={seoTitle}
        lang="ar"
        canonicalUrl={`${process.env.GATSBY_BASE_URL}latest-news/`}
        description={seoDescription}
        meta={[
          {
            property: `keywords`,
            content: ` اخر الاخبار , اخر الاخبار العالمية , اخر الاخبار العربية ,اخبار عاجلة`
          }
        ]}
      />

      <LatestNews
        fullSize
        scrollable={false}
        poll
        hideNoData={false}
        pagination
      />
    </Layout>
  )
}

export default LatestNewsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
